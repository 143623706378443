import * as React from "react"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react"

// Import Swiper styles
import "swiper/css"
import "swiper/css/effect-fade"
import "./slider.css"

// import required modules
import { EffectFade, Autoplay } from "swiper"
export const bgMap = {
  black: "black",
  yellow: "#2fb2ba",
  white: "white",
  grey: "#D4D4D4",
}
export const Section: React.FC<{
  bgColor?: string
  followsYellow?: boolean
  introBorder?: boolean
  fullWidth?: boolean
}> = ({ bgColor, followsYellow, introBorder, fullWidth, children }) => {
  const hideDescription = id => {
    const elem = document.getElementById(id)

    if (elem) {
      elem.classList.add("hidden")
      document.getElementById("overlay").classList.add("hidden")
    }
  }
  return (
    <div style={{ position: "relative" }} className={`bg-${bgColor}`}>
      {followsYellow && (
        <svg
          className={"top-border-yellow"}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill={bgMap.yellow} points="100,0 0,100 0,0" />
        </svg>
      )}

      <div
        style={{
          background: bgMap[bgColor],
          color: bgColor === "black" ? "white" : "inherit",
        }}
      >
        <div
          className={"section-container"}
          style={{
            margin: fullWidth ? "0" : `0 auto`,
            maxWidth: fullWidth ? "100%" : 960,
            padding: `1.5rem`,
            paddingTop: followsYellow ? "8rem" : `5rem`,
            zIndex: 20,
            position: "relative",
            width: fullWidth ? "50%" : "100%",
          }}
        >
          {children}
        </div>

        {followsYellow && (
          <div className="image-right">
            <>
              <Swiper
                autoHeight={true}
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                effect={"fade"}
                modules={[Autoplay, EffectFade]}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
              >
                <SwiperSlide>
                  <img
                    src={"music-therapy.jpg"}
                    alt={"Music therapy with young people."}
                  />
                </SwiperSlide>
                <SwiperSlide>
                  <img
                    src={"the-team.jpg"}
                    alt={"Relatable Role Models Team"}
                  />
                </SwiperSlide>
              </Swiper>
            </>
            <div id={"overlay"} className={"overlay hidden"}></div>

            <div id={"consultancy"} className={"text-overlay hidden"}>
              <div>
                <h3>
                  Consultancy{" "}
                  <div
                    id={"close-overlay"}
                    onClick={() => hideDescription("consultancy")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 512 512"
                      x="0px"
                      y="0px"
                      fill={"white"}
                    >
                      <path d="M501.66,10.34a8,8,0,0,0-11.32,0L256,244.69,35.16,23.84A8,8,0,0,0,23.84,35.16L244.69,256,10.34,490.34a8,8,0,0,0,11.32,11.32L256,267.31,476.84,488.16a8,8,0,0,0,11.32-11.32L267.31,256,501.66,21.66A8,8,0,0,0,501.66,10.34Z" />
                    </svg>
                  </div>
                </h3>
                <p>
                  Youth engagement consultations, both in-person and via video
                  call. We can help with:
                </p>
                <p>
                  <ul>
                    <li>
                      Facilitating youth projects, and improving retention and
                      uptake
                    </li>
                    <li>Approaches to establishing trust with young people</li>
                    <li>Service delivery plans</li>
                    <li>Conflict resolution between young people</li>
                    <li>Improving community cohesion</li>
                    <li>Networking with other youth organisations</li>
                  </ul>
                </p>
              </div>
            </div>
            <div id={"training"} className={"text-overlay hidden"}>
              <div>
                <h3>
                  Training{" "}
                  <div
                    id={"close-overlay"}
                    onClick={() => hideDescription("training")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 512 512"
                      x="0px"
                      y="0px"
                      fill={"white"}
                    >
                      <path d="M501.66,10.34a8,8,0,0,0-11.32,0L256,244.69,35.16,23.84A8,8,0,0,0,23.84,35.16L244.69,256,10.34,490.34a8,8,0,0,0,11.32,11.32L256,267.31,476.84,488.16a8,8,0,0,0,11.32-11.32L267.31,256,501.66,21.66A8,8,0,0,0,501.66,10.34Z" />
                    </svg>
                  </div>
                </h3>
                <p>
                  We provide training for youth workers, teachers, police,
                  social workers and other professionals working with young
                  people. We also deliver Parent Awareness sessions.
                </p>
                <p>Our training covers the following topics:</p>
                <p>
                  <ul>
                    <li>Gang culture</li>
                    <li>Youth violence</li>
                    <li>Child exploitation</li>
                    <li>Substance abuse</li>
                    <li>Social media</li>
                    <li>Grooming</li>
                    <li>Family breakdowns</li>
                    <li>Healthy relationships</li>
                  </ul>
                </p>
              </div>
            </div>
            <div id={"workshops"} className={"text-overlay hidden"}>
              <div>
                <h3>
                  Workshops{" "}
                  <div
                    id={"close-overlay"}
                    onClick={() => hideDescription("workshops")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 512 512"
                      x="0px"
                      y="0px"
                      fill={"white"}
                    >
                      <path d="M501.66,10.34a8,8,0,0,0-11.32,0L256,244.69,35.16,23.84A8,8,0,0,0,23.84,35.16L244.69,256,10.34,490.34a8,8,0,0,0,11.32,11.32L256,267.31,476.84,488.16a8,8,0,0,0,11.32-11.32L267.31,256,501.66,21.66A8,8,0,0,0,501.66,10.34Z" />
                    </svg>
                  </div>
                </h3>
                <p>
                  <strong>Lived Experience workshops:</strong> where our
                  Relatable Role Models who have gone through challenging
                  journeys and learned valuable lessons share their story
                  through engaging workshops.
                </p>
                <p>
                  <strong>Music workshops:</strong> where young people have
                  access to recording and music production facilities,
                  surrounded by industry professionals. They can write and
                  record their own music which can boost their confidence,
                  self-esteem and social skills. For those that want to develop
                  as artists, there's support in place to connect them with the
                  right people and opportunities.
                </p>
              </div>
            </div>
            <div id={"community-projects"} className={"text-overlay hidden"}>
              <div>
                <h3>
                  Community Projects{" "}
                  <div
                    id={"close-overlay"}
                    onClick={() => hideDescription("community-projects")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 512 512"
                      x="0px"
                      y="0px"
                      fill={"white"}
                    >
                      <path d="M501.66,10.34a8,8,0,0,0-11.32,0L256,244.69,35.16,23.84A8,8,0,0,0,23.84,35.16L244.69,256,10.34,490.34a8,8,0,0,0,11.32,11.32L256,267.31,476.84,488.16a8,8,0,0,0,11.32-11.32L267.31,256,501.66,21.66A8,8,0,0,0,501.66,10.34Z" />
                    </svg>
                  </div>
                </h3>
                <p>
                  Through creating safe spaces and delivering engaging projects
                  for young people to access, we encourage community cohesion
                  through maximising potential in young people and integrating
                  them with the wider community.
                </p>
                <p>
                  <em>Example projects we've delivered:</em>
                </p>
                <p>
                  <strong>Project Elevate:</strong> a free multi-activity summer
                  programme, where we selected influential young people to be
                  role models for their peers, and give them the responsibility
                  to lead activities and create a positive culture.
                  <br />
                  <a
                    href={"/Relatable Role Models - Project Elevate Report.pdf"}
                    download
                    target={"_blank"}
                  >
                    Download the project report
                  </a>
                </p>
                <p>
                  <strong>The Secret Place:</strong> an engaging safe space for
                  young people to access, where we also provided free music
                  making facilities for the local community, to help young
                  people develop creatively and musically. <br />
                  <a
                    href={"/Relatable Role Models - Secret Place Report.pdf"}
                    download
                    target={"_blank"}
                  >
                    Download the project report
                  </a>
                </p>
              </div>
            </div>
            <div id={"one-to-one-mentoring"} className={"text-overlay hidden"}>
              <div>
                <h3>
                  One-to-one Mentoring{" "}
                  <div
                    id={"close-overlay"}
                    onClick={() => hideDescription("one-to-one-mentoring")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      data-name="Layer 1"
                      viewBox="0 0 512 512"
                      x="0px"
                      y="0px"
                      fill={"white"}
                    >
                      <path d="M501.66,10.34a8,8,0,0,0-11.32,0L256,244.69,35.16,23.84A8,8,0,0,0,23.84,35.16L244.69,256,10.34,490.34a8,8,0,0,0,11.32,11.32L256,267.31,476.84,488.16a8,8,0,0,0,11.32-11.32L267.31,256,501.66,21.66A8,8,0,0,0,501.66,10.34Z" />
                    </svg>
                  </div>
                </h3>
                <p>
                  Building a relationship with a young person and establishing
                  trust. Allowing for honest conversation, where needs can be
                  identified and then met. We engage young people in and out of
                  education.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {introBorder && (
        <svg
          className="top-border-intro"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon fill="#000000" points="0,0 100,100 0,100" />
        </svg>
      )}
    </div>
  )
}
